<template>
  <div class="components-chart">
    <div ref="chartRef" :style="{width, height}"></div>
  </div>
</template>

<script>
export default {
  name: 'Chart',
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '23vh'
    }
  },
  data() {
    return {
      chart: undefined
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if(!newVal.series) return
        setTimeout(() => {
          this.updateOption(newVal)
        }, 100);
      },
      immediate: true
    }
  },
  methods: {
    init() {
      this.chart = this.$echarts.init(this.$refs.chartRef)
    },
    updateOption(op){
      if(!this.chart)return
      // 绘制图表
      this.chart.setOption(op, true)
      this.chart.resize()
    }
  }
}
</script>

<style>

</style>
