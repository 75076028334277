<template>
  <div class="component-trend-chart">
    <div class="radio-box">
      <el-radio-group v-model="type" size="mini" @change="changeType">
        <el-radio-button :label="1">年</el-radio-button>
        <el-radio-button :label="2">月</el-radio-button>
      </el-radio-group>
    </div>
    <Chart ref="chartRef" :options="options" height="21.5vh" />
  </div>
</template>

<script>
import Chart from './Chart.vue'

const colors = ['#00FFFF', '#66FF75', '#FFB966', '#FF6666']

export default {
  name: 'TrendChart',
  components: { Chart },
  props: {
    rendData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    rendData: {
      handler(newVal, oldVal) {
        if (!newVal) return
        this.options = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: '20%',
            show: true,
            textStyle: {
              color: 'white'
            },
            icon: 'rect'
          },
          grid: {
            top: '35%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisLine: {
              show: true
            },
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisLabel: {
              color: 'white'
            },
            axisTick: {
              show: false
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: true
            },
            axisLabel: {
              color: 'white'
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#666'
              }
            },
          },
          series: [
            {
              name: '工作',
              type: 'line',
              data: this.rendData[0],
              itemStyle: {
                color: colors[0]
              }
            },
            {
              name: '通报',
              type: 'line',
              data: this.rendData[1],
              itemStyle: {
                color: colors[1]
              }
            },
            {
              name: '漏洞',
              type: 'line',
              data: this.rendData[2],
              itemStyle: {
                color: colors[2]
              }
            },
            {
              name: '预警',
              type: 'line',
              data: this.rendData[3],
              itemStyle: {
                color: colors[3]
              }
            }
          ]
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      type: 1,
      options: {}
    }
  },
  methods: {
    changeType() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-radio-group {
  border-radius: 2px;
  border: 1px solid #00FFFF;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #333;
  background-color: #00FFFF;
  border: none;
  border-radius: 0;
}

/deep/ .el-radio-button__orig-radio+.el-radio-button__inner {
  color: #00FFFF;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.component-trend-chart {
  width: 100%;
  height: 22vh;
  background-image: url('@/assets/imgs/框@2x(4).png');
  background-size: 100% 100%;
  position: relative;

  .radio-box {
    z-index: 666;
    position: absolute;
    right: 0.5%;
    top: 3%;
  }
}
</style>
