<template>
  <div class="component-header">
    <img src="@/assets/imgs/Group 1321315042@2x.png" style="width: 100%;">
    <!-- <div class="login-info">
      <el-row type="flex" align="middle">
        <img src="@/assets/imgs/Group 3@2x.png" style="width:44px">
        <p class="username"></p>
      </el-row>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style lang="less" scoped>
  .component-header {
    position: relative;
    .login-info {
      position: absolute;
      right: 0;
      top: 40%;
      transform: translateY(-50%);
      padding: 0 14px;
      color: white;
      .username {
        width: 5vw;
        font-size: 16px;
        margin-left: 14px;
      }
    }
  }
</style>
