function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10)
      break
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      break
    default:
      return 0
      break
  }
}

export const getData = (data = {
  min: 1,
  max: 100,
  num: 12
}) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const arr = []
      for (let i = 0; i < data.num; i++) {
        arr.push(randomNum(data.min, data.max))
      }
      const res = {
        code: 200,
        data: arr,
        msg: 'success'
      }
      resolve(res)
    }, 2000)
  })
}