<template>
  <div class="component-dispose flex-row">
    <div class="card flex-col">
      <span style="color: #FFB966;">处理中</span>
      <span style="font-size: 36px;">{{ one }}</span>
    </div>
    <div class="card flex-col">
      <span style="color: #00FFFF;">已处理</span>
      <span style="font-size: 36px;">{{ two }}</span>
    </div>
    <div class="card flex-col">
      <span style="color: #027AFF;">已提交方案</span>
      <span style="font-size: 36px;">{{ three }}</span>
    </div>
    <div class="card flex-col">
      <span style="color: #66FF75;">已完结</span>
      <span style="font-size: 36px;">{{ four }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dispose',
  props: {
    rendData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    rendData: {
      handler(newVal, oldVal) {
        if (!newVal) return
        this.one = this.rendData[0]
        this.two = this.rendData[1]
        this.three = this.rendData[2]
        this.four = this.rendData[3]
      },
      immediate: true
    }
  },
  data() {
    return {
      one: 0,
      two: 0,
      three: 0,
      four: 0
    }
  },
}
</script>

<style lang="less" scoped>
  .component-dispose {
    width: 26vw;
    height: 19.5vh;
    background-image: url('@/assets/imgs/框@2x(3).png');
    background-size: 100% 100%;
    justify-content: center;
    .card {
      width: 100px;
      height: 94px;
      margin: 0 10px;
      margin-top: 38px;
      background-image: url('@/assets/imgs/Group 1321315043@2x.png');
      background-size: 100% 100%;
      color: white;
      justify-content: space-around;
    }
  }
</style>
