<template>
  <div class="component-important-level flex-row">
    <div class="card1 flex-col">
      <span style="color: #00FFFF; font-size: 36px; margin: 12px 0 20px 0;">{{ one }}</span>
      <span>低风险</span>
    </div>
    <div class="card2 flex-col">
      <span style="color: #FFB966; font-size: 36px; margin: 12px 0 20px 0;">{{ two }}</span>
      <span>中风险</span>
    </div>
    <div class="card3 flex-col">
      <span style="color: #FF6666; font-size: 36px; margin: 12px 0 20px 0;">{{ three }}</span>
      <span>高风险</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportantLevel',
  props: {
    rendData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    rendData: {
      handler(newVal, oldVal) {
        if (!newVal) return
        this.one = this.rendData[0]
        this.two = this.rendData[1]
        this.three = this.rendData[2]
      },
      immediate: true
    }
  },
  data() {
    return {
      one: 0,
      two: 0,
      three: 0
    }
  }
}
</script>

<style lang="less" scoped>
  .component-important-level {
    width: 26vw;
    height: 22vh;
    background-image: url('@/assets/imgs/框@2x(2).png');
    background-size: 100% 100%;
    justify-content: space-around;
    .card1,
    .card2,
    .card3 {
      width: 126px;
      height: 126px;
      margin-top: 40px;
      background-size: 100% 100%;
      color: white;
      justify-content: space-around;
    }
    .card1 {
      background-image: url('@/assets/imgs/图@2x.png');
    }
    .card2 {
      background-image: url('@/assets/imgs/图@2x(1).png');
    }
    .card3 {
      background-image: url('@/assets/imgs/图@2x(2).png');
    }
  }
</style>
