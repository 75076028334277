<template>
  <div id="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from './components/BigData.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style lang="less">
  body {
    padding: 0;
    margin: 0;
  }
  .flex-row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
  .flex-col {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }
  .wing {
    margin: 0 1vw;
  }
  .line {
    width: 100%;
    height: 1vh;
  }
  .mt-6 {
    margin-top: 1vh;
  }
  .mb-6 {
    margin-bottom: 1vh;
  }
</style>
