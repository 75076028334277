<template>
  <div class="component-center">
    <span class="num" style="top: 1%; left: 76%">{{ one }}</span>
    <span class="num" style="top: 14%; left: 72%">{{ two }}</span>
    <span class="num" style="top: 28%; left: 69%">{{ three }}</span>
    <span class="num" style="top: 41%; left: 66%">{{ four }}</span>
  </div>
</template>

<script>

export default {
  name: 'CenterCard',
  props: {
    rendData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    rendData: {
      handler(newVal, oldVal) {
        if (!newVal) return
        this.one = this.rendData[0]
        this.two = this.rendData[1]
        this.three = this.rendData[2]
        this.four = this.rendData[3]
      },
      immediate: true
    }
  },
  data() {
    return {
      one: 0,
      two: 0,
      three: 0,
      four: 0
    }
  },
}
</script>

<style lang="less" scoped>
.component-center {
  width: 50vw;
  height: 50vh;
  background-image: url('@/assets/imgs/Group 1321315037@2x.png');
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -35%);
  .num {
    color: #00FFFF;
    font-size: 36px;
    position: absolute;
  }
}
</style>
