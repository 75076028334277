<template>
  <div class="component-type-card">
    <Chart ref="chartRef" :options="options" height="28vh" />
  </div>
</template>

<script>
import Chart from './Chart.vue'

const colors = ['#00FBFF', '#FF9356', '#9664FF', '#E964FF']

export default {
  name: 'TypeCard',
  components: { Chart },
  props: {
    rendData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    rendData: {
      handler(newVal, oldVal) {
        if (!newVal) return
        this.options = {
          grid: {
            top: '10%',
            left: '10%',
            right: '10%',
            bottom: '3%'
          },
          legend: {
            top: '30%',
            right: '10%',
            orient: 'vertical',
            icon: 'circle',
            textStyle: {
              color: 'white'
            }
          },
          tooltip: {
            itemStyle: {
              color: 'white'
            }
          },
          series: [
            {
              type: 'pie',
              radius: [0, 80],
              center: ['50%', '50%'],
              roseType: 'area',
              data: [
                {
                  value: this.rendData[0],
                  name: '公安',
                  itemStyle: {
                    color: colors[0]
                  },
                  label: {
                    position: 'outer',
                    alignTo: 'none',
                    bleedMargin: 1,
                    color: 'white'
                  }
                },
                {
                  value: this.rendData[1],
                  name: '工具发现',
                  itemStyle: {
                    color: colors[1]
                  },
                  labelLine: {
                    length: 1,
                    length2: 10
                  },
                  label: {
                    color: 'white',
                    position: 'outside'
                  }
                },
                {
                  value: this.rendData[2],
                  name: '自身发现',
                  itemStyle: {
                    color: colors[2]
                  },
                  labelLine: {
                    length: 1,
                    length2: 10
                  },
                  label: {
                    position: 'outer',
                    alignTo: 'none',
                    bleedMargin: 1,
                    color: 'white'
                  }
                },
                {
                  value: this.rendData[3],
                  name: '上级通报',
                  itemStyle: {
                    color: colors[3]
                  },
                  labelLine: {
                    length: 1,
                    length2: 10
                  },
                  label: {
                    position: 'outer',
                    alignTo: 'none',
                    bleedMargin: 1,
                    color: 'white'
                  }
                }
              ]
            }
          ]
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      options: {}
    }
  }
}
</script>

<style lang="less" scoped>
.component-type-card {
  width: 26vw;
  height: 24vh;
  background-image: url('@/assets/imgs/框@2x.png');
  background-size: 100% 100%;
}
</style>
