<template>
  <div class="component-rank">
    <div class="radio-box">
      <el-radio-group v-model="type" size="mini" @change="changeType">
        <el-radio-button :label="1">单位</el-radio-button>
        <el-radio-button :label="2">系统</el-radio-button>
      </el-radio-group>
    </div>
    <Chart ref="chartRef" :options="options" height="22vh" />
  </div>
</template>

<script>
import Chart from './Chart.vue'

const colors = ['#FFEB36', '#BCE1F2', '#FFB966', '#027AFF']

export default {
  name: 'Rank',
  components: { Chart },
  props: {
    rendData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    rendData: {
      handler(newVal, oldVal) {
        if (!newVal) return
        this.options = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          top: '30%',
          left: '3%',
          right: '8%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLabel: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitLine: false
        },
        yAxis: {
          axisLine: {
            show: false
          },
          axisTick: false,
          type: 'category',
          data: [
            {value: 'D单位', textStyle: {color: colors[3]}},
            {value: 'C单位', textStyle: {color: colors[2]}},
            {value: 'B单位', textStyle: {color: colors[1]}},
            {value: 'A单位', textStyle: {color: colors[0]}},
          ]
        },
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: 14,
            data: [
              {value: this.rendData[0], itemStyle: {color: colors[3]}},
              {value: this.rendData[1], itemStyle: {color: colors[2]}},
              {value: this.rendData[2], itemStyle: {color: colors[1]}},
              {value: this.rendData[3], itemStyle: {color: colors[0]}}
            ],
            label: {
              show: true,
              position: 'right',
              textStyle: {
                color: 'white'
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: [0, 10, 10, 0]
              }
            }
          }
        ]
      }
      },
      immediate: true
    }
  },
  data() {
    return {
      type: 1,
      options: {}
    }
  },
  methods: {
    changeType() {
      this.$emit('change')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-radio-group {
  border-radius: 2px;
  border: 1px solid #00FFFF;
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #333;
  background-color: #00FFFF;
  border: none;
  border-radius: 0;
}
/deep/ .el-radio-button__orig-radio+.el-radio-button__inner {
  color: #00FFFF;
  background-color: transparent;
  border: none;
  border-radius: 0;
}
.component-rank {
  width: 26vw;
  height: 22vh;
  background-image: url('@/assets/imgs/框@2x(1).png');
  background-size: 100% 100%;
  position: relative;
  .radio-box {
    z-index: 666;
    position: absolute;
    right: 2%;
    top: 3%;
  }
}
</style>
