<template>
  <div class="swiper-box flex-row">
    <!-- <p style="color: red; font-size: 30px; position: fixed; bottom: 30%; left: 30%">{{ current % 2 }}</p> -->
    <img v-if="current > 1 " class="icon-left" src="@/assets/imgs/Frame@2x-1.png" style="width: 2vw;" @click="current--">
    <img v-else class="icon-left" src="@/assets/imgs/Frame@2x(1).png" style="width: 2vw;">

    <img v-if="current >= 2 "  class="icon-right" src="@/assets/imgs/Frame@2x (1).png" style="width: 2vw;">
    <img v-else class="icon-right" src="@/assets/imgs/Frame@2x(2).png" style="width: 2vw;" @click="current++">

    <div class="swiper-list flex-row">
      <template v-if="current % 2">
        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(4).png" style="width: 2vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x.png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已完成<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">处理中<span style="color: #FFB966; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">延期<span style="color: #00FFFF; margin-left: 6px;">{{ one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x.png" style="width: 3vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(1).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已报单位<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">上报事件的单位<span
                    style="color: #FFB966; margin-left: 6px;">{{
                      one }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">上报事件<span style="color: #00FFFF; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(6).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">上报平安单位<span style="color: #00FFFF; margin-left: 6px;">{{
                  one }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(1).png" style="width: 3vw; margin-top: 4px">
            <!-- <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">34</span></span> -->
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(2).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">封堵IP<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">新增白名单<span style="color: #FFB966; margin-left: 6px;">{{
                  one }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">延期<span style="color: #00FFFF; margin-left: 6px;">{{ one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(2).png" style="width: 2vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(3).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已处置<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">处理中<span style="color: #FFB966; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(3).png" style="width: 2vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(4).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已反馈<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已完结<span style="color: #00FFFF; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">处理中<span style="color: #FFB966; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x.png" style="width: 3vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(1).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已报单位<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">上报事件的单位<span
                    style="color: #FFB966; margin-left: 6px;">{{
                      one }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">上报事件<span style="color: #00FFFF; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(6).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">上报平安单位<span style="color: #00FFFF; margin-left: 6px;">{{
                  one }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(4).png" style="width: 2vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x.png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已完成<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">处理中<span style="color: #FFB966; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">延期<span style="color: #00FFFF; margin-left: 6px;">{{ one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(1).png" style="width: 3vw; margin-top: 4px">
            <!-- <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">34</span></span> -->
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(2).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">封堵IP<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">新增白名单<span style="color: #FFB966; margin-left: 6px;">{{
                  one }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">延期<span style="color: #00FFFF; margin-left: 6px;">{{ one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(3).png" style="width: 2vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(4).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已反馈<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(2).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已完结<span style="color: #00FFFF; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">处理中<span style="color: #FFB966; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-item">
          <div class="title-box flex-row">
            <img src="@/assets/imgs/近30天销售额概况@2x(2).png" style="width: 2vw; margin-top: 4px">
            <span style="color: rgba(255, 255, 255, 0.70);">总数<span style="color: #00FFFF; margin-left: 6px;">{{ one
                }}</span></span>
          </div>
          <div class="flex-row content" style="width: 100%; height: 84%; justify-content: center;">
            <img src="@/assets/imgs/Group 1321314604@2x(3).png" style="width: 5.5vw; height: 120px;">
            <div class="flex-col" style="margin-left: 0.2vw; align-items: flex-start;">
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(3).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">已处置<span style="color: #66FF75; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
              <div class="flex-row mb-6" style="justify-content: flex-start;">
                <img src="@/assets/imgs/Group 1321314642@2x(1).png" style="width: 1vw; margin-right: 6px;">
                <span style="color: rgba(255, 255, 255, 0.70);">处理中<span style="color: #FFB966; margin-left: 6px;">{{
                  one
                    }}</span></span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
export default {
  name: 'SwiperBox',
  props: {
    rendData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    rendData: {
      handler(newVal, oldVal) {
        if (!newVal) return
        this.one = this.rendData[0]
        this.two = this.rendData[1]
        this.three = this.rendData[2]
        this.four = this.rendData[3]
      },
      immediate: true
    }
  },
  data() {
    return {
      current: 1,
      one: 0,
      two: 0,
      three: 0,
      four: 0
    }
  },
  methods: {
    
  }
}
</script>

<style lang="less" scoped>
.swiper-box {
  width: 100%;
  position: relative;
  padding: 0 3vw;
  justify-content: center;
  .icon-left,
  .icon-right {
    cursor: pointer;
    position: fixed;
    bottom: 0%;
    transform: translateY(-200%);
  }

  .icon-left {
    left: 1vw;
  }

  .icon-right {
    right: 1vw;
  }

  .swiper-list {
    width: 92vw;
    .swiper-item {
      width: 17.5vw;
      height: 20vh;
      background-color: rgba(2, 122, 255, 0.1);

      .title-box {
        color: white;
        background-color: rgba(2, 122, 255, 0.20);
        border-left: 4px solid #027AFF;
        padding: 6px 8px;
      }
    }
  }
}
</style>
