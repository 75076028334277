<template>
  <div class="page-bigdata" v-loading="loadingData">
    <b-header />

    <div class="flex-row wing mb-6">
      <div class="flex-col">
        <type-card :rendData="bigData.type" />
        <div class="line"></div>
        <important-level :rendData="bigData.important" />
      </div>
      <div class="center" style="position: relative;">
        <center-card :rendData="bigData.centerData" />
      </div>
      <div class="flex-col">
        <div class="flex-row mb-6" style="width: 26vw;">
          <el-select v-model="agency" placeholder="选择单位" size="mini" @change="init">
            <el-option label="某单位1" :value="1"></el-option>
            <el-option label="某单位2" :value="2"></el-option>
            <el-option label="某单位3" :value="3"></el-option>
          </el-select>
          <el-date-picker v-model="daterange" size="small" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" @change="init">
          </el-date-picker>
        </div>
        <rank :rendData="bigData.rank" @change="init" />
        <div class="line"></div>
        <dispose :rendData="bigData.dispose" />
      </div>
    </div>

    <div class="flex-row wing">
      <trend-chart :rendData="bigData.trend" @change="init" />
    </div>

    <div class="line"></div>

    <div class="flex-row wing">
      <swiper-card :rendData="bigData.swiperData" />
    </div>

  </div>
</template>

<script>
import Chart from './Chart.vue'
import BHeader from './BHeader.vue'
import TypeCard from './TypeCard.vue'
import ImportantLevel from './ImportantLevel.vue'
import CenterCard from './CenterCard.vue'
import Rank from './Rank.vue'
import Dispose from './Dispose.vue'

import TrendChart from './TrendChart.vue'
import SwiperCard from './SwiperCard.vue'

import { getData } from '@/api'

export default {
  name: 'BigData',
  components: {
    Chart,
    BHeader,
    TypeCard,
    ImportantLevel,
    CenterCard,
    Rank,
    Dispose,
    TrendChart,
    SwiperCard
  },
  data() {
    return {
      loadingData: false,
      agency: undefined,
      daterange: undefined,
      bigData: {
        type: undefined,
        important: undefined,
        centerData: undefined,
        rank: undefined,
        dispose: undefined,
        trend: undefined,
        swiperData: undefined
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loadingData = true
      Promise.all([
        this.getType(),
        this.getImportant(),
        this.getCenter(),
        this.getRank(),
        this.getDispose(),
        this.getTrend(),
        this.getSwiper(),
      ]).then(() => {
        setTimeout(() => {
          this.loadingData = false
        }, 2200);
      })
    },
    getType() {
      getData({min: 33, max: 99, num: 4})
      .then(({ data }) => {
        this.bigData.type = data
      })
    },
    getImportant() {
      getData({min: 33, max: 99, num: 3})
      .then(({ data }) => {
        this.bigData.important = data
      })
    },
    getCenter() {
      getData({min: 333, max: 9999, num: 4})
      .then(({ data }) => {
        this.bigData.centerData = data
      })
    },
    getRank() {
      getData({min: 333, max: 9999, num: 4})
      .then(({ data }) => {
        this.bigData.rank = data
      })
    },
    getDispose() {
      getData({min: 33, max: 999, num: 4})
      .then(({ data }) => {
        this.bigData.dispose = data
      })
    },
    getTrend(){
      getData({min: 33, max: 999, num: 48})
      .then(({ data }) => {
        this.bigData.trend = [
          data.splice(0, 12), 
          data.splice(0, 12), 
          data.splice(0, 12), 
          data.splice(0, 12)
        ]
      })
    },
    getSwiper() {
      getData({min: 33, max: 999, num: 20})
      .then(({ data }) => {
        this.bigData.swiperData =data
      })
    }
  }
}
</script>

<style lang="less">
.page-bigdata {
  width: 100vw;
  height: 100vh;
  background:
    -webkit-linear-gradient(top, transparent 39px, rgba(255, 255, 255, 0.08) 40px),
    -webkit-linear-gradient(left, transparent 39px, rgba(255, 255, 255, 0.08) 40px);
  background-color: #001028;
  background-size: 40px 40px;
  overflow: hidden;

  .el-select {
    background-color: rgba(2, 122, 255, 0.5);
    width: 48%;
    padding: 3px 0;

    .el-input__inner {
      background-color: transparent;
      border: 0;
      border-radius: 0;
    }
  }

  .el-date-editor {
    background-color: rgba(2, 122, 255, 0.5);
    border: 0;
    border-radius: 0;
    width: 48%;
    padding: 6px;
    color: white;

    .el-range-input {
      background-color: transparent;
    }
  }
}
</style>
